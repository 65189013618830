.container.svelte-cctgkx.svelte-cctgkx {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.light_container.svelte-cctgkx.svelte-cctgkx {
  display: flex;
  justify-content: space-evenly;
}
.lamp.svelte-cctgkx.svelte-cctgkx {
  position: relative;
  --bg: rgb(9, 117, 225);
  --glare: linear-gradient(
    180deg,
    rgba(9, 117, 225, 0.751) 0%,
    rgba(9, 118, 225, 0.751) 52%,
    rgba(9, 118, 225, 0.2518384062609419) 87%,
    rgba(9, 118, 225, 0) 100%
  );
  --light: linear-gradient(
    180deg,
    rgb(9, 117, 225) 0%,
    rgba(9, 117, 225, 1) 20%,
    rgba(9, 117, 225, 0.3) 60%,
    rgba(9, 117, 225, 0) 70%
  );
}
.lamp.purple.svelte-cctgkx.svelte-cctgkx {
  --bg: rgb(222, 23, 222);
  --glare: linear-gradient(
    180deg,
    rgba(222, 23, 222, 0.751) 0%,
    rgba(222, 23, 222, 0.751) 52%,
    rgba(222, 23, 222, 0.2518384062609419) 87%,
    rgba(222, 23, 222, 0) 100%
  );
  --light: linear-gradient(
    180deg,
    rgb(222, 23, 222) 0%,
    rgba(222, 23, 222, 1) 20%,
    rgba(222, 23, 222, 0.3) 60%,
    rgba(222, 23, 222, 0) 70%
  );
}
.lamp.aqua.svelte-cctgkx.svelte-cctgkx {
  --bg: rgb(20, 227, 227);
  --glare: linear-gradient(
    180deg,
    rgba(20, 227, 227, 0.751) 0%,
    rgba(20, 227, 227, 0.751) 52%,
    rgba(20, 227, 227, 0.2518384062609419) 87%,
    rgba(20, 227, 227, 0) 100%
  );
  --light: linear-gradient(
    180deg,
    rgb(20, 227, 227) 0%,
    rgba(20, 227, 227, 1) 20%,
    rgba(20, 227, 227, 0.3) 60%,
    rgba(20, 227, 227, 0) 70%
  );
}
.lamp.lime.svelte-cctgkx.svelte-cctgkx {
  --bg: rgb(83, 231, 27);
  --glare: linear-gradient(
    180deg,
    rgba(183, 231, 27, 0.751) 0%,
    rgba(183, 231, 27, 0.751) 52%,
    rgba(183, 231, 27, 0.2518384062609419) 87%,
    rgba(183, 231, 27, 0) 100%
  );
  --light: linear-gradient(
    180deg,
    rgb(183, 231, 27) 0%,
    rgba(183, 231, 27, 1) 20%,
    rgba(183, 231, 27, 0.3) 60%,
    rgba(183, 231, 27, 0) 70%
  );
}
.lamp.pink.svelte-cctgkx.svelte-cctgkx {
  --bg: rgb(219, 44, 73);
  --glare: linear-gradient(
    180deg,
    rgba(219, 44, 73, 0.751) 0%,
    rgba(219, 44, 73, 0.751) 52%,
    rgba(219, 44, 73, 0.2518384062609419) 87%,
    rgba(219, 44, 73, 0) 100%
  );
  --light: linear-gradient(
    180deg,
    rgb(219, 44, 73) 0%,
    rgba(219, 44, 73, 1) 20%,
    rgba(219, 44, 73, 0.3) 60%,
    rgba(219, 44, 73, 0) 70%
  );
}
.lamp_foot.svelte-cctgkx.svelte-cctgkx,
.lamp_body.svelte-cctgkx.svelte-cctgkx {
  border: 2px solid rgb(11, 11, 11);
  background-color: #000;
}
.lamp_foot.svelte-cctgkx.svelte-cctgkx {
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50% 0;
  height: 1.5rem;
  width: 0.5rem;
}
.lamp_body.svelte-cctgkx.svelte-cctgkx {
  --time: 8s;
  position: relative;
  translate: 0 1rem;
  height: 4rem;
  width: 2rem;
  transform-origin: top center;
  border-top-left-radius: 0.5rem 5rem;
  border-top-right-radius: 0.5rem 5rem;
  animation: svelte-cctgkx-rotate var(--time) ease-in-out infinite;
}
.lamp.odd.svelte-cctgkx .lamp_body.svelte-cctgkx {
  animation-delay: 4s;
}
.flame.svelte-cctgkx.svelte-cctgkx {
  content: "";
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 50%;
  translate: -50% 0;
  width: 2rem;
  height: 300px;
  border-top-left-radius: 20px 20rem;
  border-top-right-radius: 20px 20rem;
  border-bottom-left-radius: 1 10px;
  background: beige;
  background: linear-gradient(
    180deg,
    beige,
    #fffff589 30%,
    #ffffff20 70%,
    #fff0
  );
  filter: blur(2px);
}
.glare.svelte-cctgkx.svelte-cctgkx,
.light.svelte-cctgkx.svelte-cctgkx {
  position: absolute;
  top: 100%;
  left: 50%;
  translate: -50% 0;
  border-radius: 3.75rem 3.75rem 2.5rem 2.5rem/50rem 50rem 5rem 5rem;
  height: 1000px;
  background: var(--bg);
}
.light.svelte-cctgkx.svelte-cctgkx {
  width: 3rem;
  border-top-left-radius: 1.25rem 20rem;
  border-top-right-radius: 1.25rem 20rem;
  z-index: 1;
  background: var(--light);
}
.glare.svelte-cctgkx.svelte-cctgkx {
  width: 5rem;
  background: var(--glare);
  filter: blur(5px);
}
@keyframes svelte-cctgkx-rotate {
  0% {
    rotate: 45deg;
  }
  50% {
    rotate: -45deg;
  }
  to {
    rotate: 45deg;
  }
}
.container.svelte-1e4hf98.svelte-1e4hf98 {
  width: 100vw;
  max-width: 100vw;
  aspect-ratio: 665/375;
  position: relative;
  overflow: hidden;
}
.inside.svelte-1e4hf98.svelte-1e4hf98 {
  filter: brightness(0.1);
}
.door.svelte-1e4hf98.svelte-1e4hf98,
.door_overlay.svelte-1e4hf98.svelte-1e4hf98 {
  position: absolute;
  aspect-ratio: 1034/1800;
  width: 17.5vw;
  top: 50%;
  left: 50%;
  translate: -52% -43%;
}
.door.svelte-1e4hf98.svelte-1e4hf98 {
  transform-style: preserve-3d;
  transform-origin: left;
  transform: perspective(2300px);
}
.door_overlay.svelte-1e4hf98.svelte-1e4hf98 {
  background-color: #0b0401;
}
.door.svelte-1e4hf98 img.svelte-1e4hf98 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.door_back.svelte-1e4hf98.svelte-1e4hf98 {
  transform: rotateY(-180deg);
}
img.svelte-1e4hf98.svelte-1e4hf98,
.background.svelte-1e4hf98.svelte-1e4hf98 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
}
.background.svelte-1e4hf98.svelte-1e4hf98 {
  background-color: #26262636;
  transition: backround-color 0.2s ease-out;
}
.outside_open.svelte-1e4hf98.svelte-1e4hf98 {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.light.svelte-1e4hf98.svelte-1e4hf98 {
  --border-col: rgba(93, 9, 142, 0.486);
  position: absolute;
  transform-origin: bottom center;
  bottom: 0;
  border-top: 900px solid var(--border-col);
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  height: 0px;
  width: 3rem;
  filter: drop-shadow(0px 0px 20px white);
}
.light_beige.svelte-1e4hf98.svelte-1e4hf98 {
  --border-col: rgba(245, 245, 220, 0.63);
}
.light_left.svelte-1e4hf98.svelte-1e4hf98 {
  rotate: 40deg;
  animation: svelte-1e4hf98-light_left 2.5s ease-in-out infinite;
}
.light_left.light_beige.first.svelte-1e4hf98.svelte-1e4hf98,
.light_right.light_beige.first.svelte-1e4hf98.svelte-1e4hf98 {
  animation-delay: 1s;
}
.light_left.light_purple.svelte-1e4hf98.svelte-1e4hf98,
.light_right.light_purple.svelte-1e4hf98.svelte-1e4hf98 {
  animation-delay: 0.5s;
}
.light_right.svelte-1e4hf98.svelte-1e4hf98 {
  rotate: -40deg;
  animation: svelte-1e4hf98-light_right 2.5s ease-in-out infinite;
  right: 0;
}
.bg_music.svelte-1e4hf98.svelte-1e4hf98 {
  height: 1px;
  width: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
}
@keyframes svelte-1e4hf98-party_bg {
  0% {
    background-color: #ff00002c;
  }
  40% {
    background-color: #ff02;
  }
  60% {
    background-color: #0080001f;
  }
  80% {
    background-color: #0000ff2d;
  }
  to {
    background-color: #ff00002c;
  }
}
@keyframes svelte-1e4hf98-light_left {
  0% {
    rotate: 30deg;
  }
  50% {
    rotate: 60deg;
  }
  to {
    rotate: 30deg;
  }
}
@keyframes svelte-1e4hf98-light_right {
  0% {
    rotate: -30deg;
  }
  50% {
    rotate: -60deg;
  }
  to {
    rotate: -30deg;
  }
}
.container.svelte-drd39m {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.1rem;
  min-height: 100vh;
  font-family: Satoshi, sans-serif;
  background-size: cover;
  background-repeat: no-repeat;
}
.title.svelte-drd39m {
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #5e523f;
}
.text.svelte-drd39m {
  max-width: 27em;
  font-size: 42px;
  text-align: center;
  color: #352e24;
}
:root {
  font-size: 16px;
}
body {
  max-width: 100vw;
  overflow-x: hidden;
}
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
